<template>
<div class="blog">
<h1>Über mich</h1><br/>
                  <h2>BERUFSERFAHRUNG</h2>

                  <h3>2021 bis heute - Finanz Informatik GmbH & Co. KG</h3>

                  <p>Abteilungsleiter „End-2-End Windows Active Directory"
                    <ul>
                      <li>End-2-End Verantwortung für die Multimandanten Microsoft Active Directory Infrastruktur der SFG</li>
                      <li>Fachliche und disziplinarische Führung von 13 Mitarbeitern</li> 
                      <li>Personal- und Budgetverantwortung</li>
                      <li>Projektleitung nach IPMA </li>
                    </ul>
                  </p>

                  <h3>2018 bis 2021 - Finanz Informatik GmbH & Co. KG</h3>

                  <p>Abteilungsleiter „Entwicklung Active Directory und Terminalservices“
                    <ul>
                      <li>Verantwortung für die Entwicklung der Multimandaten Domänen- und Terminalserverinfrastruktur der SFG</li>
                      <li>Fachliche und disziplinarische Führung von 12 Mitarbeitern</li>
                      <li>Aufbau von Teams</li>
                      <li>Personal- und Budgetverantwortung</li>
                    </ul>
                  </p>

                  <h3>2016 bis heute – Nebenberuflich: Freelancer</h3>

                  <p>Freelancer für IT-Consulting & Development
                    <ul>
                      <li>Beratung</li>
                    </ul>
                  </p>

                  <h3>2010 bis 2018 - Finanz Informatik GmbH & Co. KG</h3>

                  <p>Systemorganisator Microsoft Windows Active Directory
                    <ul>
                      <li>Design und Betrieb der Multimandanten Domäneninfrastruktur auf Basis von Microsoft Windows Active Directory</li>
                      <li>Umsetzung von Anforderungen in einer stark regulierten Client-/Serverinfrastruktur</li>
                      <li>Durchführung von Migrationsprojekten</li>
                    </ul>
                  </p>

                  <h2>AUSBILDUNG</h2>

                  <h3>2014 bis 2016 - Fakultät für Oekonomie und Management</h3>
                  <p>Master of Science (M.Sc.), Studiengang IT-Management</p>              

                  <h3>2010 bis 2014 - Hochschule der Sparkassen-Finanzgruppe</h3>
                  <p>Bachelor of Science (B.Sc.), Studiengang Wirtschaftsinformatik für Finanzdienstleister</p>

                  <h3>2007 bis 2010 - Finanz Informatik GmbH & Co. KG</h3>
                  <p>Ausbildung zum IT-Systemkaufmann</p>

</div>

</template>

<script>
export default {
  name: 'home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.blog {
  max-width: 50vw;
  margin: 1rem auto;
  text-align: left;

  .back {
    background-color: #A9D18E;;
    color: white;
    margin-top: 5rem;
    text-decoration: none;
    padding: 10px 15px;
    border: 1px solid currentColor;
    border-radius: .5rem;
    transition: all .3s ease;
    &:hover {
      background-color: transparent;
      color: #A9D18E;;
    }
  }
  /deep/ {
    h1 {
      font-size: 3rem;
      margin-bottom: .2rem;
      color: #A9D18E;
      text-align: center;
    }
    h4 {
      margin-bottom: 3rem;
      color: #35495e;
    }
    pre {
      overflow-x: auto;
      background-color: #35495e;
      color: white;
      border-radius: .3rem;
      padding: 1rem;
    }
    img {
      max-width: 100%;
    }
  }
}

.headline {
  text-transform: uppercase;
  margin: 1rem auto;
  font-size: 4rem;
}
img {
  display: block;
  margin: 0 auto;
  width: 150px;
}

h2 {
  color: #35495e;
  text-transform: capitalize;
  margin-bottom: 2rem;
}

h3 {
  color: #A9D18E;;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  .subtitle {
    color: grey;
    font-size: .98rem;
    float: right;
    font-weight: normal;
  }
}
</style>
