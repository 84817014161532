import Vue from 'vue'
import Router from 'vue-router'
//import Home from './views/Home.vue'
import Entry from './views/Entry.vue'
import Imprint from './views/Imprint.vue'
//import Blog from './views/Blog.vue'
import Home from './views/Home.vue'
import Privacy from './views/Privacy.vue'

Vue.use(Router)

import BlogEntries from './statics/data/blogs.json';

const blogRoutes = Object.keys(BlogEntries).map(section => {
  const children = BlogEntries[section].map(child => ({
    path: child.id,
    name: child.id,
    component: () => import(`./markdowns/${section}/${child.id}.md`)
  }))
  return {
    path: `/${section}`,
    name: section,
    component: () => import('./views/Blog_detail.vue'),
    children
  }
})

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },{
      path: '/blog',
      name: 'blog',
      component: Entry
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    ...blogRoutes
  ]
})
