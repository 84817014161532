<template>
  <div class="sections">
    <div v-for="(section, index) in Object.keys(entries)" :key="index" class="group">
      <div class="section" v-for="entry in entries[section]" :key="entry.id">
        <div v-if="entry.active" class="entry">
          <h3 @click="$router.push({name: entry.id})">
            {{entry.title}}
            <p>
              <span class="subtitle">{{entry.date}}</span>
            </p>
          </h3>
          <p>{{entry.description}}</p>
          <p v-if="entry.description" style="text-align:right" @click="$router.push({name: entry.id})"><span>Weiter lesen...</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BLOGENTRIES from '@/statics/data/blogs.json'

export default {
  name: 'entry',
  computed: {
    entries() {
      return BLOGENTRIES
    }
  }
}
</script>

<style lang="scss" scoped>

.headline {
  text-transform: uppercase;
  margin: 4rem auto;
  font-size: 4rem;
}
img {
  display: block;
  margin: 0 auto;
  width: 150px;
}
h2 {
  color: #35495e;
}
span {
  color: #A9D18E;
  &:hover{
    text-decoration: underline;
  }
}
h3 {
  color: #A9D18E;;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  .subtitle {
    color: grey;
    font-size: .98rem;
    float: right;
    font-weight: normal;
  }
}


.sections {
  max-width: 50vw;
  margin: 0 auto;
  margin-top: 1rem;
  text-align: left;
}

.section {
  margin-bottom: 3rem;
}

.group {
  margin-bottom: 4rem;
}
</style>
